import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const TeamMemberListing = () => {
  const data = useStaticQuery(graphql`
    query {
      teamMembers: allSanityTeamMembers(
        sort: { order: ASC, fields: _createdAt }
      ) {
        edges {
          node {
            fullname
            title
            headshot {
              asset {
                url
              }
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const { teamMembers } = data
  const team = teamMembers.edges

  return (
    <Layout>
      <SEO title="Our Leadership Team" />

      <Helmet
        bodyAttributes={{
          class: "bg-power-lines",
        }}
      />
      <div className="container mx-auto py-24">
        <h1 className="mx-4 md:mx-0 uppercase font-bold tracking-wide mb-8 text-5xl border-b border-gray-800">
          Our Leadership Team
        </h1>
        <div className="px-4 md:px-0 flex flex-wrap md:flex-no-wrap md:-mx-4">
          {team.map((t, idx) => {
            let member = t.node
            return (
              <div className="w-full md:w-1/3 md:mx-4 my-4" key={idx}>
                <span className="block relative">
                  <span className="block bottom-0 absolute p-8 font-black text-4xl md:text-5xl leading-tight text-shadow-md">
                    {member.fullname}
                  </span>
                  <img
                    src={`${member.headshot.asset.url}?w=600&h=600&fit=crop&sat=-100`}
                    alt={`${member.fullname} Headshot`}
                    className="shadow-md"
                  />
                </span>
                <span className="py-2 inline-block text-sm">
                  {member.title}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default TeamMemberListing
